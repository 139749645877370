.image-gallery-slide img {
    height: auto;
}

.image-gallery-slide {
    min-height: 600px;
    height: max-content;
}

.fullscreen .image-gallery-slide img {
    height: auto;
}

.fullscreen .image-gallery-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100vH;
}